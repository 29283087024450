import { redirect } from 'react-router-dom';
import axios from 'axios';
// import { Firebase } from './firebase';

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const getNameAndPic = () => {
  const name = localStorage.getItem('name');
  const photoURL = localStorage.getItem('photoURL');
  return { name, pic: photoURL };
};

export const setAuthUser = async (access_token) => {
  const url = 'https://cognito-idp.us-east-2.amazonaws.com/';
  const headers = {
    'Content-Type': 'application/x-amz-json-1.1',
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser'
  };
  const data = JSON.stringify({
    AccessToken: access_token
  });

  try {
    const response = await axios.post(url, data, { headers });
    const userData = response.data.UserAttributes;
    console.log('[Get User Endpoint]: ', userData);
    const name = userData[userData.findIndex((datum) => datum.Name === 'name')].Value;
    const email = userData[userData.findIndex((datum) => datum.Name === 'email')].Value;
    const phone = userData[userData.findIndex((datum) => datum.Name === 'phone_number')].Value;
    const sub = userData[userData.findIndex((datum) => datum.Name === 'sub')].Value;
    const birthdate = userData[userData.findIndex((datum) => datum.Name === 'birthdate')].Value;
    localStorage.setItem('email', email);
    localStorage.setItem('phone', phone);
    localStorage.setItem('sub', sub);
    localStorage.setItem('name', name);
    localStorage.setItem('birthdate', birthdate);
  } catch (error) {
    console.error('[Get User Endpoint]: ', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.clear();
  return redirect('/');
};

export const getAwsTokens = async (code) => {
  const isDev = process.env.REACT_APP_ENV;
  const tokenEndpoint = 'https://easygsm.auth.us-east-2.amazoncognito.com/oauth2/token';
  const clientId = '7fojrge4411pgtt5mti4h9jfcb';
  const redirectUri = `${isDev ? 'http://localhost:3000' : 'https://easygsm.cloud'}`;

  const data = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: clientId,
    code: code,
    redirect_uri: redirectUri
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  await axios
    .post(tokenEndpoint, data, { headers })
    .then((response) => {
      console.log('[Token Endpoint]: ', response.data);
      localStorage.setItem('accessToken', response.data.access_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      localStorage.setItem('idToken', response.data.id_token);
    })
    .catch((error) => {
      console.error('[Token Endpoint]: ', error);
    });
  return getAccessToken();
};
