import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import ThemeCustomization from 'themes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Routes from 'routes';
import reportWebVitals from './reportWebVitals';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //dd

const container = document.getElementById('root');
const root = createRoot(container);
const router = createBrowserRouter(Routes, { basename: '/' });

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeCustomization>
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
      </ThemeCustomization>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
