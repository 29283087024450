// project import

import ScrollTop from 'components/ScrollTop';
// import Routes from 'routes';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  return <ScrollTop />;
};

export default App;
