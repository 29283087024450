import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { logout } from 'utils/localStorage';

// lazy loading
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/dashboard',
  element: <MainLayout />,
  id: 'root',
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard/color',
      element: <Color />
    },
    {
      path: '/dashboard/shadow',
      element: <Shadow />
    },
    {
      path: '/dashboard/typography',
      element: <Typography />
    },
    {
      path: '/dashboard/icons/ant',
      element: <AntIcons />
    },
    {
      path: '/dashboard/logout',
      action: logout
    }
  ]
};

export default MainRoutes;
