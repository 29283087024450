// import axios from 'axios';
// import { getAccessToken } from 'utils/localStorage';

// const isDev = process.env.NODE_ENV;

const AuthProviderInterface = {
  redirectUserForOAuth() {},

  signInUser() {},

  signOutUser() {
    // const clientId = '7fojrge4411pgtt5mti4h9jfcb';
    // const url = `https://easygsm.auth.us-east-2.amazoncognito.com/oauth2/revoke`;
    // const access_token = getAccessToken();
    // const headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // };
    // axios
    //   .post(
    //     url,
    //     new URLSearchParams({
    //       client_id: clientId,
    //       token: access_token
    //     }),
    //     { headers }
    //   )
    //   .then(() => {
    //     console.log('[Revoke Endpoint]: Successfully logged out');
    //   })
    //   .catch((err) => console.error('[Revoke Endpoint]: ', err));
  }
};

export default AuthProviderInterface;
